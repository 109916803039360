import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { useVigoToast } from 'src/@core/hooks/useVigoToast';
import { AttachmentDto, AttachmentModel, BaseModel } from 'src/models/attachment';
import { deleteAttachmentById, toggleAttachmentIsHidden } from 'src/services/attachment';

export const useAttachmentPanelMutations = () => {
  // Hooks
  const { vigoToast } = useVigoToast({});

  const deleteAttachmentMutation = useMutation({
    mutationFn: async (data: any) => {
      return new Promise(async (resolve, reject) => {
        const deleteAttachmentToastId = vigoToast.loading('Deleting attachment');

        let res: any;

        try {
          const reqBody: AxiosRequestConfig<BaseModel> = {
            data: { version: data.attachmentVersion }
          };

          res = await deleteAttachmentById(data.attachmentDtoId, reqBody);

          vigoToast.success('Attachment deleted', { id: deleteAttachmentToastId });

          return resolve(res);
        } catch (e) {
          vigoToast.error('Error deleting attachment', { id: deleteAttachmentToastId });

          return reject();
        }
      });
    }
  });

  const toggleFileVisibilityMutation = useMutation({
    mutationFn: async (data: AttachmentDto) => {
      return new Promise(async (resolve, reject) => {
        const toggleFileVisibilityToastId = vigoToast.loading('Updating file visibility');

        let res: any;
        try {
          const model: AttachmentModel = {
            fileType: data.fileType!,
            name: data.name!,
            uri: data.uri!,
            attachmentTypeId: data.attachmentTypeId,
            comment: data.comment,
            entityId: data.entityId,
            entityTypeId: data.entityTypeId,
            isHidden: data.isHidden,
            size: data.size,
            thumbnailUri: data.thumbnailUri,
            version: data.version
          };

          await toggleAttachmentIsHidden(data.id!, model);

          vigoToast.success('File visibility updated', { id: toggleFileVisibilityToastId });

          return resolve(res);
        } catch (e) {
          vigoToast.error('Error updating file visibility', { id: toggleFileVisibilityToastId });

          return reject();
        }
      });
    }
  });

  return { deleteAttachmentMutation, toggleFileVisibilityMutation };
};
